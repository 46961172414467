<script>
import CommentInput from "@/components/CommentInput.vue";
import _, {find} from "lodash";
import AvWaveform from "vue-audio-visual/src/components/AvWaveform";
import Btn from "@/components/Form/Btn.vue";
import moment from "moment";
import {mapActions, mapGetters} from 'vuex';
import UserAvatar from "@/components/UserAvatar.vue";
import MessagesEdit from "@/components/MessagesEdit.vue";
import OpenFile from '@/components/OpenFile.vue';
import DocumentFormDialog from '@/components/DocumentFormDialog.vue';

export default {
    name: "Messages",

    components: {MessagesEdit, UserAvatar, Btn, CommentInput, AvWaveform, OpenFile, DocumentFormDialog},

    props: {
        entity: {
            type: String,
            required: true
        },

        entity_uuid: {
            type: String,
            required: true
        },

        socketName: {
            type: String,
            required: true
        },
    },

    data() {
        return {
            messages: [],
            loading: false,
            dialog_file: false,
            files: [],
            page: 1,
            lastPage: null,
            total: 0,
            toolbar_reply: false,
            text: '',
            readerTimer: null,
            editingMessage: null,
            dialogForm: false,
            document_flow_urls_form: [],
            dialogOpenFile: false,
            fileOpen: {},
        }
    },

    computed: {
        ...mapGetters(['language']),

        chatMessagesDate() {
            let msgs = this.messages.filter(msg => msg.deleted_at === null);
            return msgs.reduce((acc, message) => {
                const date = moment(message.date).format("YYYY-MM-DD");

                if (!acc[date]) {
                    acc[date] = [];
                }

                acc[date].push(message);

                return acc;
            }, {});
        },

        showMore: {
            get() {
                return this.page < this.lastPage;
            }
        },
    },

    watch: {
        chatMessagesDate: {
            immediate: true,
            handler(value) {
                this.updateObserver();
            }
        },

        entity_uuid: {
            immediate: true,
            handler(value) {
                this.init();
            }
        }
    },

    methods: {
        ...mapActions(['fetchNotificationGroups']),
        ...mapActions('messages', {
            fetchGlobalMessages: 'fetchMessages'
        }),

        async init() {
            this.initIntersectionObserver();
            await this.websocket();

            this.fetchMessages().then((data) => {
                this.messages = data.data;
                this.lastPage = data.meta.last_page;
                this.page = data.meta.current_page;
                this.total = data.meta.total;
            });
            this.scrollCard();
        },

        loadMore() {
            this.fetchMessages(this.page + 1).then((data) => {
                this.messages = [...data.data, ...this.messages];
                this.lastPage = data.meta.last_page;
                this.page = data.meta.current_page;
                this.total = data.meta.total;
            })
        },

        fetchMessages(page = 1) {
            this.loading = true;

            let params = {};
            if (page) {
                params.page = page;
            }

            return new Promise((resolve, reject) => {
                this.$http
                    .get(`admin/${this.entity}/${this.entity_uuid}/message`, {
                        params
                    })
                    .then(res => {
                        let messages = res.body.data.reverse();

                        resolve({
                            ...res.body,
                            data: messages
                        });

                        // this.messages = [...messages, ...this.messages];
                        // this.page = res.body.meta.current_page;
                        // this.lastPage = res.body.meta.last_page;
                        // this.total = res.body.meta.total;
                    })
                    .catch(err => {
                        console.log(err);
                        this.$toastr.error(this.$t('failed_to_get_list_messages'))
                        reject(err);
                    })
                    .finally(end => {
                        this.loading = false
                    })
            })

        },

        async sentMessage() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (typeof this.text === 'undefined' || this.text === null || this.text === '') {
                return;
            }

            if (this.language) {
                formData.append('language', this.language)
            }

            if (this.reply_uuid) {
                formData.append('reply_uuid', this.reply_uuid)
            }

            if (this.text) {
                formData.append('message', this.text)
            }

            // Add
            await this.$http
                .post(`admin/${this.entity}/${this.entity_uuid}/message`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(({body}) => {
                    this.text = ''
                    this.replyClose();
                    this.scrollCard();

                    this.messages.push(body.data);
                })
                .catch(err => {
                    console.log(err);
                    this.$toastr.error(this.$t('message_has_not_been_added'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },

        downloadFileMessage(url, file_name) {
            fetch(url)
                .then((resp) => resp.blob())
                .then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.href = url;
                    a.download = file_name;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    this.$toastr.success(this.$t('file_download_started'))
                })
                .catch(() =>
                    this.$toastr.error(this.$t('file_download_error'))
                );
        },

        async sentMessageFile(files) {
            const _this = this;
            let formData, blob;
            this.dialog_file = false;
            this.documents = []
            let is_image = false;

            for (const file of files) {
                let replyMessage = {}

                is_image = this.mimeTypeImage(file.type);
                formData = new FormData();
                if (this.reply_uuid) {
                    formData.append('reply_uuid', this.reply_uuid)
                }

                formData.append('file[type_mime]', file.type)
                formData.append('file[size]', file.size)
                formData.append('file[file_name]', file.name)
                formData.append('file[access]', file.access)
                formData.append('file[is_image]', is_image ? 1 : null)
                file.blob = await this.convertBlobToBase64(file.blob)

                if (file.blob) {
                    blob = this.dataURL64toBlob(file.blob)

                    if (blob) {
                        formData.append('file[file]', blob, file.type)
                    }
                }

                // Add
                this.$http
                    .post(`admin/${this.entity}/` + this.entity_uuid + "/message", formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(({body}) => {
                        this.messages.push(body.data);
                        this.files = [];
                    })
                    .catch(err => {
                        this.files = files;
                        this.$toastr.error(this.$t('page_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        // var index = this.chatMessages.length + 1
                        // if (typeof this.chatMessages[index] != "undefined") {
                        //     this.chatMessages[index].delivered = true;
                        // }
                    })


            }
            this.replyClose()
        },

        scrollCard() {
            this.$nextTick(() => {
                if (this.$refs.scrollContainer !== undefined) {
                    const container = this.$refs.scrollContainer.$el;
                    const firstUnread = this.$refs.scrollContainer.$el.querySelector(".chat-message--new");

                    if (firstUnread) {
                        const containerRect = container.getBoundingClientRect();
                        const elementRect = firstUnread.getBoundingClientRect();
                        const offset = elementRect.top - containerRect.top + container.scrollTop;

                        container.scrollTo({
                            top: offset,
                            behavior: "smooth",
                        });
                    } else {
                        container.scrollTop = container.scrollHeight;
                    }
                }
            });
        },

        async handleIntersection(entries) {
            for (const entry of entries) {
                if (entry.isIntersecting) {
                    const index = this.$refs.comment.indexOf(entry.target);
                    const id = parseInt(entry.target.dataset.messageId);
                    // const date = entry.target.dataset.messageDate;
                    const message = find(this.messages, {id: id});

                    console.log(message);

                    if (message && message.is_readed === false) {
                        setTimeout(async () => {
                            await this.markAsRead(message);
                        }, 3000);
                    }

                    this.observer.unobserve(entry.target);
                }
            }
        },

        initIntersectionObserver() {
            const options = {
                root: null,
                rootMargin: "0px",
                threshold: 0.1,
            };

            this.observer = new IntersectionObserver(this.handleIntersection, options);
            this.updateObserver();
        },

        updateObserver() {
            // Обновляем наблюдателя для новых комментариев
            this.$nextTick(() => {
                if (this.$refs.comment) {
                    this.$refs.comment.forEach((comment) => {
                        if (comment.classList.contains("chat-message--new")) {
                            this.observer.observe(comment);
                        }
                    });
                }
            });
        },

        markAsRead(message) {
            this.$http
                .post(`admin/${this.entity}/${this.entity_uuid}/message/${message.id}/reader`)
                .then(res => {
                    message.is_readed = true;
                    this.fetchGlobalMessages();
                }).catch(err => {
            });

            if(this.readerTimer) {
                clearTimeout(this.readerTimer);
            }

            this.readerTimer = setTimeout(() => {
                this.fetchNotificationGroups();
            }, 5000);
        },

        handleClick(click, msg, date) {
            switch (click) {
                case 'delete':
                    this.deleteMessages(msg, date)
                    break;
                case 'reply' :
                    this.replyOpen(msg)
                    break;
            }
        },

        replyOpen(msg) {
            this.reply_uuid = msg.uuid
            this.reply_msg = msg.comment;
            this.reply_name = msg.name;
            this.toolbar_reply = true
        },

        replyClose() {
            this.reply_uuid = null
            this.reply_name = null
            this.toolbar_reply = false
            this.reply_msg = {}
        },

        async deleteMessages(msg) {
            this.loading = true;

            await this.$http
                .delete(`admin/${this.entity}/${this.entity_uuid}/message/${msg.uuid}`)
                .then(res => {
                    let k = _.findKey(this.messages, {uuid: msg.uuid})
                    this.messages.splice(k, 1)
                    this.$toastr.success(this.$t('message_has_been_deleted'))
                })
                .catch(err => {
                    this.$toastr.error(this.$t('message_has_not_been_deleted'))
                })
                .finally(end => {
                    this.loading = false
                })

        },

        editMessage(msg) {
            this.editingMessage = msg;
        },

        async translation(text, comment_uuid = '') {
            const _this = this;
            const formData = new FormData()
            this.progress = 0
            this.loading = true

            formData.append('text', text)
            if (comment_uuid !== '') {
                formData.append('comment_uuid', comment_uuid)
            }

            if (this.language) {
                formData.append('language', this.language)
            }

            if (this.$route.params.id) {
                formData.append('uuid', this.$route.params.id)
            }

            // Save
            await this.$http
                .post(`admin/translate_text`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    if (res.body.data.comment) {
                        let foundObjectIndex = _.findIndex(this.messages, {uuid: comment_uuid});

                        if (foundObjectIndex !== -1) {
                            this.messages[foundObjectIndex].comment = res.body.data.comment.comment;
                        }
                    }

                    this.$toastr.success(this.$t('translated'))
                })
                .catch(err => {
                    this.$toastr.error(this.$t('translation_error'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })

        },

        getTranslation(translations) {
            if (!Array.isArray(translations) || translations.length === 0) {
                translations = [];
                return null;
            }
            let translationObject = translations.find(obj => obj && Object.prototype.hasOwnProperty.call(obj, this.language));
            return translationObject ? translationObject[this.language] : null;
        },

        websocket() {
            window.Echo
                .private(`App.Models.Admin.${this.$auth.user().id}`)
                .listen(`.Event.Message`, this.onMessage);
        },

        onMessage(event) {
            console.log(event);
            if(this.entity_uuid === event.messageable_uuid) {
                //Если пришло добавление сообщение
                if (event && event.id && event.id > 0) {
                    if (event.comment && event.comment.type) {
                        let i = _.findIndex(this.messages, {'uuid': event.uuid})
                        if(i === -1) {
                            this.messages.push(event);
                        } else {
                            this.$set(this.messages, i, event);
                        }
                    }
                } else if (event && event.type && event.type === 'delete') {
                    //Если пришло удаление сообщение
                    if (event.id_array && event.id_array > 0 && event.date) {
                        let k = _.findKey(event.id, this.messages[event.date])

                        if(k) {
                            this.messages[k].splice(event.id, 1);
                        }
                    }
                }
            }

            this.scrollCard();
        },

        ImageView(msg) {
            this.$emit('image-view', msg) // Сделать глобальный вьювер
        },

        ImageViewClose(msg) {
            this.$emit('image-view-close') // Сделать глобальный вьювер
        }
    },

    async mounted() {

    }
}
</script>

<template>
    <div>
        <v-card>
            <v-card-text class="px-0 mx-0">
                <v-row class="px-0 mx-0">
                    <v-col v-if="showMore" cols="12" class="d-flex justify-center">
                        <Btn plain @click="loadMore">
                            {{ $t('more_messages') }}
                        </Btn>
                    </v-col>
                    <v-col cols="12" class="px-0 mx-0" v-if="chatMessagesDate.length !== 0">
                        <v-card ref="scrollContainer" id="book" flat max-height="800" class="h_scroll px-0 mx-0">
                            <v-card-text class="py-0 my-0 px-0 mx-0">
                                <div v-for="(chatMessages, date) in chatMessagesDate" :key="date">
                                    <div class="date_day_week">{{ getDateDayWeek(date) }}</div>

                                    <v-col v-for="msg in chatMessages" :key="msg.id" ref="comment" class="py-0 my-0 chat-message" :data-message-id="msg.id" :data-message-date="date" :class="{'chat-message--new': !msg.is_readed}">
                                        <v-card :id="'div_'+msg.uuid" flat class="py-1 mt-7 px-3 mx-0">

                                            <v-card class="d-flex mb-2" flat v-if="msg.admin">
                                                <UserAvatar :img="msg.admin.photo" />

                                                <div class="title_subtitle mr-auto px-2">
                                                    <div>
                                                        <span class="v-list-item__title">{{ msg.admin.name }}</span>
                                                        <v-menu offset-y>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn icon
                                                                       v-bind="attrs"
                                                                       v-on="on"
                                                                       x-small
                                                                >
                                                                    <v-icon>mdi-dots-vertical</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            <v-list>
                                                                <v-list-item @click="replyOpen(msg)">
                                                                    <v-list-item-title>{{ $t('reply') }}</v-list-item-title>
                                                                </v-list-item>
                                                                <v-list-item v-if="msg?.admin.id === $auth.user().id && $moment().diff($moment(msg.created_at), 'minutes') < 5 && msg.comment.type === 'text'" @click="editMessage(msg)">
                                                                    <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
                                                                </v-list-item>
                                                                <v-list-item v-if="msg?.admin.id === $auth.user().id && $moment().diff($moment(msg.created_at), 'minutes') < 5" @click="deleteMessages(msg)">
                                                                    <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
                                                                </v-list-item>
                                                                <v-list-item v-if="msg.comment.type === 'file'" @click="addDocumentForm(msg.comment)">
                                                                    <v-list-item-title>{{ $t('sent') }}</v-list-item-title>
                                                                </v-list-item>
                                                                <v-list-item v-if="msg.comment.type === 'file'" @click="downloadFileMessage(msg.comment.value, msg.comment.file_name)">
                                                                    <v-list-item-title>{{ $t('download') }}</v-list-item-title>
                                                                </v-list-item>
                                                            </v-list>
                                                        </v-menu>
                                                    </div>
                                                    <div class="v-list-item__subtitle">{{ formatPhoneNumber(msg.phone) }}</div>
                                                </div>

                                                <div class="chat_time chat-message__info">
                                                    <div>
                                                        <v-icon
                                                            :color="msg.is_readed ? 'primary' : ''"
                                                            class="mx-0 px-0 pr-1 my-0 py-0"
                                                            right
                                                        >
                                                            {{ msg.delivered ? 'mdi-check-all' : 'mdi-check' }}
                                                        </v-icon>
                                                        {{ $moment(msg.created_at).format('HH:mm') }}
                                                    </div>
                                                </div>
                                            </v-card>


                                            <v-row class="chat_text_row">
                                                <v-col cols="12" class="px-2 pb-1 ">
                                                    <div v-if="msg.reply" class="pb-2">
                                                        <div v-if="msg.reply.type === 'text'" class="py-2 d-inline-flex reply_background">
                                                            <div class="mr-auto pt-2 px-2">
                                                                <span class="reply_name">{{ msg.reply.name }}</span>
                                                                <span class="font-size-10 reply_name">{{ msg.reply.value }}</span>
                                                            </div>
                                                        </div>
                                                        <div v-if="msg.reply.type === 'image'" class="rounded-0 rounded-r-0 py-2 d-inline-flex reply_background">
                                                            <v-img v-if="msg.reply.type === 'image'"
                                                                   class="my-0 py-0 ml-2"
                                                                   max-width="50"
                                                                   :src="msg.reply.value"
                                                            >
                                                            </v-img>
                                                            <div class="pt-2 px-2">
                                                                <span class="reply_name">{{ msg.reply.name }}</span>
                                                                <span class="reply_name font-size-10">
                                                                    {{ $t('photo') }}
                                                                    <v-icon left x-small>
                                                                       mdi-camera-outline
                                                                    </v-icon>
                                                                </span>
                                                            </div>

                                                        </div>
                                                        <div v-if="msg.reply.type === 'file'" class="rounded-r-0 py-2 d-inline-flex reply_background">
                                                            <div class="mr-auto pt-2 px-2">
                                                                <span class="reply_name">{{ msg.reply.name }}</span>
                                                                <span class="reply_name font-size-10">
                                                                    {{ msg.reply.file_name }}
                                                                    <v-icon left x-small>
                                                                       mdi-paperclip
                                                                    </v-icon>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div v-if="msg.reply.type === 'sound'" class="rounded-r-0 py-2 d-inline-flex reply_background">
                                                            <div class="mr-auto pt-2 px-2">
                                                                <span class="reply_name">{{ msg.reply.name }}</span>
                                                                <span class="reply_name font-size-10 pt-1">
                                                                    <av-waveform
                                                                        class="canvas_hide"
                                                                        :canv-width="0"
                                                                        :canv-height="0"
                                                                        :audio-src="msg.comment.value">
                                                                    </av-waveform>
                                                                    {{ $t('record') }}
                                                                    <v-icon left x-small>
                                                                      mdi-play-circle-outline
                                                                    </v-icon>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div v-if="msg.comment.type === 'text'" class="chat_text">
                                                        {{ msg.comment.value }}
                                                        <div v-if="getTranslation(msg.comment.translations)" class="chat_text mb-0">
                                                            <p style="background-color: #F5F5F5;padding: 20px;" class="mb-0">
                                                                {{ getTranslation(msg.comment.translations) }}
                                                            </p>
                                                            <p class="font-size-10">{{ $t('transferred_via') }}</p>
                                                        </div>
                                                        <v-btn v-else icon :disabled="loading" @click="translation(msg.comment.value, msg.uuid)" :title="$t('translate')">
                                                            <v-icon>mdi-google-translate</v-icon>
                                                        </v-btn>
                                                    </div>
                                                    <div v-if="msg.comment.type === 'image'" class="chat_text">
                                                        <v-img
                                                            class="mb-3 cursor_pointer"
                                                            max-width="250"
                                                            :src="msg.comment.value"
                                                            @click="ImageView(msg)"
                                                        >

                                                            <v-row v-if="msg.delivered ? false : true"
                                                                   class="fill-height ma-0"
                                                                   align="center"
                                                                   justify="center"
                                                            >
                                                                <v-progress-circular
                                                                    indeterminate
                                                                    color="grey lighten-5"
                                                                ></v-progress-circular>
                                                            </v-row>
                                                        </v-img>
                                                    </div>
                                                    <div v-if="msg.comment.type === 'file'" class="chat_text">
                                                        <v-btn
                                                            color="primary"
                                                            class="white--text"
                                                            @click="openFile(msg.comment)"
                                                        >
                                                            <v-icon
                                                                left
                                                                dark
                                                            >
                                                                {{ !msg.delivered ? 'mdi-loading mdi-spin' : 'mdi-cloud-upload' }}
                                                            </v-icon>

                                                            {{ msg.comment.file_name }} ({{ (Number(msg.comment.size) / 1000).toFixed(1) + ' KB' }} )

                                                        </v-btn>
                                                    </div>
                                                    <div v-if="msg.comment.type === 'sound'" class="chat_text">
                                                        <av-waveform
                                                            class="canvas_hide"
                                                            :canv-width="0"
                                                            :canv-height="0"
                                                            :audio-src="msg.comment.value">
                                                        </av-waveform>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-card>

                                    </v-col>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" class="ma-0 pa-0 client-detail__reply" :style="chatMessagesDate.length !== 0 ? 'border-top: solid 1px #F3F3F3 !important;' : '' ">
                        <v-card flat>
                            <v-toolbar
                                class="mb-2 toolbar_reply rounded-0"
                                height="60px"
                                elevation="0"
                                v-if="toolbar_reply"
                            >
                                <div class="reply">
                                    <div class="pt-0 my-0" v-if="reply_msg.type === 'text'">
                                        <span class="reply_name"> {{ reply_name }} </span>
                                        <span class="font-size-10">{{ reply_msg.value }}</span>
                                    </div>
                                    <div v-if="reply_msg.type === 'image'" class="d-flex mx-0 px-0">
                                        <v-img
                                            v-if="reply_msg.type === 'image'"
                                            class="ml-1 my-0"
                                            max-width="50"
                                            :src="reply_msg.value"
                                        >
                                        </v-img>
                                        <div class="mr-auto pt-0 px-2">
                                            <span class="reply_name">{{ reply_name }}</span>
                                            <span class="reply_name font-size-10">
                                                {{ $t('photo') }}
                                                <v-icon left x-small>
                                                    mdi-camera-outline
                                                </v-icon>
                                            </span>
                                        </div>
                                    </div>
                                    <div v-if="reply_msg.type === 'file'" class="my-0">
                                        <div class="pt-0">
                                            <span class="reply_name">{{ reply_name }}</span>
                                            <span class="font-size-10">
                                                {{ reply_msg.file_name }}
                                                <v-icon left x-small>
                                                    mdi-paperclip
                                                </v-icon>
                                            </span>
                                        </div>
                                    </div>
                                    <div v-if="reply_msg.type === 'sound'" class="my-0">
                                        <div class="pt-0">
                                            <span class="reply_name">{{ reply_name }}</span>
                                            <span class="font-size-10">
                                               {{ $t('record') }}
                                               <v-icon left x-small>
                                                   mdi-play-circle-outline
                                               </v-icon>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <v-spacer></v-spacer>
                                <v-toolbar-items>
                                    <v-btn
                                        icon
                                        @click="replyClose"
                                    >
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                </v-toolbar-items>
                            </v-toolbar>

                            <CommentInput
                                v-model="text"
                                :files="files"
                                @send="sentMessage"
                                @sendFiles="sentMessageFile"
                            />

                            <MessagesEdit
                                v-model="editingMessage"
                                @input="editingMessage = null"
                                :entity_type="entity"
                                :entity_uuid="entity_uuid"
                            />
                        </v-card>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <OpenFile :dialogOpenFile.sync="dialogOpenFile" :file="fileOpen" nameKey="openMessage"/>

        <DocumentFormDialog
            :dialogForm="dialogForm"
            :document_flow_urls_form="document_flow_urls_form"
            :closeForm = "true"
            @dialog-form-close = "dialogFormClose"
        ></DocumentFormDialog>
    </div>
</template>

<style scoped lang="scss">

</style>
